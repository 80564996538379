import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import type { CreateEntity, EntitiesPayload } from '../../../../types/model/entity';
import type { PaginationRequest } from '../../../../types/model/shared';
import { getFakePromise } from '../../../../utils/common';
import {
  ADD_ENTITIES,
  CREATE_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY,
  FETCH_ATTRIBUTES,
  FETCH_ATTRIBUTES_FOR_RULES,
  FETCH_ATTRIBUTE_VALUES,
  FETCH_BASIC_ENTITIES,
  FETCH_COMBINED_ORGANIZATION_USERS,
  FETCH_CURRENT_SUBSCRIPTION,
  FETCH_ENTITIES,
  FETCH_ENTITY_COUNT,
  FETCH_ENTITY_DETAILS,
  FETCH_GLOBAL_ENTITIES,
  FETCH_GROUP_ENTITIES,
  FETCH_INFINITE_ENTITIES,
  FETCH_SPECIFIC_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_ENTITIES,
  FETCH_UPLOAD_TEMPLATE,
  FETCH_USERS_BY_ORGANIZATION,
  FETCH_USER_ORGANIZATION,
  IMPORT_SOURCES_URLS,
  IS_VALID_ENTITY,
  UPLOAD_COMPETITORS_ADMIN,
  UPLOAD_ENTITIES,
  UPLOAD_ENTITIES_ADMIN,
} from '../../../constants/queryKeys';
import {
  addEntities,
  createEntity,
  deleteEntity,
  editEntity,
  fetchAttributeValues,
  fetchAttributes,
  fetchEntities,
  fetchEntitiesAdmin,
  fetchEntity,
  fetchEntityAdmin,
  fetchEntityCount,
  fetchEntityCountAdmin,
  fetchGlobalEntities,
  fetchGroupEntities,
  isValidEntityName,
  uploadEntities,
  uploadEntitiesAdmin,
} from '../../../queries';
import {
  deleteEntityAdmin,
  exportEntities,
  fetchAttributesForRules,
  fetchDownloadTemplate,
  fetchEntitiesByIds,
  uploadCompetitorsAdmin,
  uploadSourcesAdmin,
} from '../../../queries/domains/ass/entity';
import { isCustomersRealm, isOperationsRealm } from '../../../utils/misc';

export function useBasicEntities(entityIds: string[]) {
  return useQuery({
    queryKey: [FETCH_BASIC_ENTITIES, { entityIds }],
    queryFn: () => fetchEntitiesByIds(entityIds),
    refetchOnWindowFocus: false,
    enabled: true, //ids.length > 0,
  });
}

export function useEntities(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
) {
  const queryFunction = isOperationsRealm() ? fetchEntitiesAdmin : fetchEntities;

  return useQuery({
    queryKey: [FETCH_ENTITIES, { pagination, sortKey, searchKey, organizationId }],
    queryFn: () => queryFunction(pagination, sortKey, searchKey, organizationId),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useInfiniteEntities(pageSize: number, sortKey: string, searchKey?: string) {
  return useInfiniteQuery({
    queryKey: [FETCH_INFINITE_ENTITIES, { limit: pageSize, sortKey, searchKey }],
    queryFn: ({ pageParam }) =>
      fetchEntities({ offset: pageParam, limit: pageSize }, sortKey, searchKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
    placeholderData: keepPreviousData,
  });
}

export function useEntityCount(organizationId?: string, enabled = true) {
  return useQuery({
    queryKey: [FETCH_ENTITY_COUNT, { organizationId }],
    queryFn: () => {
      return isOperationsRealm()
        ? fetchEntityCountAdmin(organizationId)
        : fetchEntityCount(organizationId);
    },
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useEntity(entityId: string) {
  return useQuery({
    queryKey: [FETCH_ENTITY_DETAILS, entityId],
    queryFn: () => fetchEntity(entityId),
    refetchOnWindowFocus: false,
    enabled: Boolean(entityId),
  });
}

export function useEntityAdmin(entityId: string, organizationId?: string) {
  return useQuery({
    queryKey: [FETCH_ENTITY_DETAILS, entityId, organizationId],
    queryFn: () => fetchEntityAdmin(entityId, organizationId as string),
    refetchOnWindowFocus: false,
    enabled: Boolean(organizationId),
  });
}

export function useGroupEntities(
  groupId: string,
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_GROUP_ENTITIES, { groupId, pagination, sortKey, searchKey }],
    queryFn: () => fetchGroupEntities(groupId, pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useAttributes() {
  return useQuery({
    queryKey: [FETCH_ATTRIBUTES],
    queryFn: fetchAttributes,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: true,
  });
}

export function useAttributesForRules() {
  return useQuery({
    queryKey: [FETCH_ATTRIBUTES_FOR_RULES],
    queryFn: fetchAttributesForRules,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: true,
  });
}

export function useAttributeValues(attribute?: string) {
  return useQuery({
    queryKey: [FETCH_ATTRIBUTE_VALUES, { attribute }],
    queryFn: () => fetchAttributeValues(attribute),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: true,
  });
}

export function useDeleteEntity({
  shouldRefetchSubscription,
}: {
  shouldRefetchSubscription?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation<string, Error, string>({
    mutationFn: (entityId: string) => {
      return isCustomersRealm() ? deleteEntity(entityId) : deleteEntityAdmin(entityId);
    },
    mutationKey: [DELETE_ENTITY],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITIES] });
      if (shouldRefetchSubscription) {
        queryClient.refetchQueries({ queryKey: [FETCH_SPECIFIC_SUBSCRIPTION] });
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_ENTITIES] });
      }
      if (isCustomersRealm()) {
        queryClient.refetchQueries({ queryKey: [FETCH_GROUP_ENTITIES] });
        queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
        queryClient.refetchQueries({ queryKey: [FETCH_CURRENT_SUBSCRIPTION] });
      } else {
        queryClient.refetchQueries({ queryKey: [FETCH_USERS_BY_ORGANIZATION] });
      }
    },
  });
}

export function useUploadEntities() {
  return useMutation({
    mutationFn: (formData: FormData) =>
      // Setting minimum respond time to 6sec to have enough time to display different loading states
      Promise.all([uploadEntities(formData), getFakePromise(6000)]).then((values) => values[0]),
    mutationKey: [UPLOAD_ENTITIES],
  });
}

export function useUploadEntitiesAdmin() {
  return useMutation({
    mutationFn: (formData: FormData) => uploadEntitiesAdmin(formData),
    mutationKey: [UPLOAD_ENTITIES_ADMIN],
  });
}

export function useUploadCompetitorsAdmin() {
  return useMutation({
    mutationFn: ({ formData, organizationId }: { formData: FormData; organizationId?: string }) => {
      if (organizationId == null) {
        throw new Error('Organization id is missing. Please check the route url');
      }
      return uploadCompetitorsAdmin(formData, organizationId);
    },
    mutationKey: [UPLOAD_COMPETITORS_ADMIN],
  });
}

export function useUploadSourcesAdmin() {
  return useMutation({
    mutationFn: (formData: FormData) => uploadSourcesAdmin(formData),
    mutationKey: [IMPORT_SOURCES_URLS],
  });
}

export function useAddEntities({
  shouldRefetchSubscriptionEntities,
}: {
  shouldRefetchSubscriptionEntities?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (entities: EntitiesPayload) => addEntities(entities),
    mutationKey: [ADD_ENTITIES],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITIES] });
      queryClient.removeQueries({ queryKey: [FETCH_USER_ORGANIZATION] });
      queryClient.refetchQueries({ queryKey: [FETCH_CURRENT_SUBSCRIPTION] });
      if (shouldRefetchSubscriptionEntities) {
        queryClient.refetchQueries({ queryKey: [FETCH_SPECIFIC_SUBSCRIPTION] });
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_ENTITIES] });
      }
    },
  });
}

export function useGlobalEntities(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_GLOBAL_ENTITIES, { pagination, sortKey, searchKey }],
    queryFn: () => fetchGlobalEntities(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useCreateEntity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (entity: CreateEntity) => createEntity(entity),
    mutationKey: [CREATE_ENTITY],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_GLOBAL_ENTITIES] });
    },
  });
}

export function useEditEntity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editEntity,
    mutationKey: [EDIT_ENTITY],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_GLOBAL_ENTITIES] });
    },
  });
}

export function useIsValidEntityName() {
  return useMutation({
    mutationFn: (name: string) => isValidEntityName(name),
    mutationKey: [IS_VALID_ENTITY],
  });
}

export function useDownloadTemplate() {
  return useMutation({
    mutationFn: fetchDownloadTemplate,
    mutationKey: [FETCH_UPLOAD_TEMPLATE],
  });
}

export function useExportEntities() {
  return useMutation({
    mutationFn: (data: { sortKey: string; searchKey?: string; groupId?: string }) =>
      exportEntities(data.sortKey, data.searchKey, data.groupId),
  });
}
