export const temporaryHiddenEnvironments = {
  'CXP-customers-competitors': ['none'],
  'CXP-customers-import-new-entities-button': ['none'],
  'CXP-customers-survey-insights': ['none'],
  'CXP-customers-survey-manager': ['staging', 'production'],
  'CXP-customers-survey-editor': ['production'],
  'CXP-customers-analytics-reports': ['none'],
  'CXP-customers-analytics-reports-list': ['none'],
  'CXP-customers-analytics-reports-sent': ['none'],
  'CXP-customers-review-providers-connect': ['none'],
  'CXP-customers-organization-profile': ['none'],
  'CXP-ops-manage-organizations': ['staging', 'production'],
  'CXP-poc-reviews-dialog': ['staging', 'production'],
  'CXP-customers-access-groups': ['none'],
  'CXP-customers-subscription': ['none'],
  'CXP-customers-add-entity': ['none'],
  'CXP-customers-benchmark': ['none'],
  'CXP-customers-inbox-survey-flag-as-inappropriate': ['none'],
  'CXP-customers-reports': ['none'],
  'CXP-customers-inbox-reports': ['production'],
  'CXP-customers-custom-attributes': ['none'],
  'CXP-customers-visit-data': ['production'],
};
/*
    add all to array to hide everywhere,
    add particular envs to hide for those envs only,
    comment or remove the key to show everywhere
    Use none for typescript compatibility with isTemporaryHidden util
*/
export type TemporaryHiddenFeature = keyof typeof temporaryHiddenEnvironments;
